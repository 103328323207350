'use strict';

angular.module('enervexSalesappApp').controller('TodolistCtrl', function($scope, $state, $filter, $q, $stateParams, Account, TodolistsService, User, FileUploader, Auth, Util, _) {
	$scope.jobLabel = Util.jobLabel;
	$scope.attachments = [];
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.todolistId = $stateParams.todolistId;

	$scope.me = Auth.getCurrentUser

	$scope.account = Account.get({
		id: $stateParams.accountId
	});
	if ($stateParams.mode == "edit") {
		$scope.mode = "edit"
	}
	if ($scope.mode === 'edit'){
		$scope.editForm = true;
	}
	$scope.editState = function(){
		// $stateParams.mode = 'edit';
		$state.go('todolist', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			todolistId: $scope.todolistId,
			mode: 'edit'
		}, {
			inherit: false,
			reload: true
		});
	}


	TodolistsService.getJob().$promise.then(function(job){
		$scope.job = job;
		$scope.isInternalMember = Auth.isInternalMember($scope.job);
		$scope.members = [];
		_.each(job.members, function(member){
			$scope.members.push(member.user);
		});
		$scope.item = {
			notifyMembers:[]
		}
		Util.setNotifyMembersType($scope.item, $scope.job)

		if ($stateParams.todolistId != "new") {
			fetchComments();
			fetchTodoList();
			fetchTodos();
		} else {
			$scope.todolist = {
				notifyMembers:[],
				visibility: Util.defaultVisibility($scope.job)
			}
			$scope.target = $scope.todolist;
			Util.setNotifyMembersType($scope.todolist, $scope.job);
		}
	});
	function fetchTodos() {
		TodolistsService.getTodos().$promise.then(function(todos){
			_.each(todos,function(todo){
				var dueDate = new Date(todo.assignAt);
				if (todo.assignAt){
					todo.assignAt = dueDate;
					dueDate = $filter('date')(dueDate, 'dd/MM/yyyy');
				}
			})
			$scope.todos = todos;
		});
	}
	function fetchTodoList() {
		TodolistsService.getTodoList().$promise.then(function(todolist){
			$scope.todolist = todolist;
			$scope.target = $scope.todolist;
			$scope.checkedMembers = $scope.todolist.explicitMembers;
			Util.setNotifyMembersType($scope.todolist, $scope.job);
		});

	}
	$scope.createTodo = function(item) {
		item.todolist = $stateParams.todolistId;
		item.visibility = $scope.todolist.visibility;
		item.explicitMembers = $scope.todolist.explicitMembers;
		if (item.notifyMembersType === 'all'){
			item.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (item.notifyMembersType === 'some') {
			item.notifyMembers = $scope.checkedEmailMembers;
		} else if (item.notifyMembersType === 'none'){
			item.notifyMembers = [];
		}
		TodolistsService.newTodo(item).$promise.then(function(res) {
			$scope.item = {
				notifyMembers:[]
			};
			Util.setNotifyMembersType($scope.item, $scope.job)
			fetchTodos()
		});
		$scope.todoFormArea = false;
	}
	$scope.checkedMembers = [];
	$scope.change = function() {
		$scope.formChanged = true;
	}
	$scope.toggleCheck = Util.toggleCheck;
	$scope.memberOf = Util.memberOf;
	$scope.createTodoList = function(todoList) {
		if (todoList.visibility === 'explicit'){
			todoList.explicitMembers = $scope.checkedMembers;
		}
		if (todoList.notifyMembersType === 'all'){
			todoList.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (todoList.notifyMembersType === 'some') {
			todoList.notifyMembers = $scope.checkedEmailMembers;
		} else if (todoList.notifyMembersType === 'none'){
			todoList.notifyMembers = [];
		}
		TodolistsService.newTodoList(todoList).$promise.then(function(res) {
			$state.go('todolist', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				todolistId: res._id
			}, {
				inherit: false,
				reload:true
			});
		});
	}
	$scope.todoComplete = function(todo){
		if (todo.status === 'open') {
			todo.status = 'closed';
		} else {
			todo.status = 'open';
		}
		TodolistsService.updateTodo(todo).$promise.then(function(res){
			fetchTodoList();
		});
	}
	$scope.showTodoFormArea = function() {
		$scope.todoFormArea = true;
	}

	$scope.changeTodo = function (todo){
		TodolistsService.updateTodo(todo);
	}
	$scope.cancelEdit = function(){
		// $scope.mode = '';
		$state.go('todolist', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			todolistId: $scope.todolistId,
		}, {
			inherit: false,
			reload:true
		});
		// fetchTodoList();
		// $scope.editForm = false;
	}
	$scope.deleteTodoList = function(todolist){
		TodolistsService.deleteTodoList(todolist).$promise.then(function(res){
			$state.go('todolists', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
			}, {
				inherit: false,
				reload:true
			});
		})
	}
	$scope.saveTodoListChange = function(todolist){
		if (todolist.visibility === 'explicit'){
			todolist.explicitMembers = $scope.checkedMembers;
		} else {
			todolist.explicitMembers = [];
		}
		if (todolist.notifyMembersType === 'all'){
			todolist.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (todolist.notifyMembersType === 'some') {
			todolist.notifyMembers = $scope.checkedEmailMembers;
		} else if (todolist.notifyMembersType === 'none'){
			todolist.notifyMembers = [];
		}
		TodolistsService.updateTodolist(todolist).$promise.then(function(res){
			$state.go('todolist', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				todolistId: $scope.todolistId,
			}, {
				inherit: false,
				reload: true
			});
			// fetchTodoList();
			// $scope.formChanged = false;
		});
	}

	$scope.checkedEmailMembers = [];
	$scope.toggleEmailCheck = function(member){
		$scope.formChanged = true;
		var memberId = member.user._id
		if ($scope.checkedEmailMembers.indexOf(memberId) === -1){
			$scope.checkedEmailMembers.push(member.user._id);
		} else {
			$scope.checkedEmailMembers.splice($scope.checkedEmailMembers.indexOf(memberId), 1);
		}
	}
	$scope.setChecks = false;
	$scope.selectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		_.each($scope.job.members, function(member){
			$scope.checkedEmailMembers.push(member.user._id);
		})
		$scope.setChecks = true;
		console.log($scope.checkedEmailMembers)
	}
	$scope.deselectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		$scope.setChecks = false;
	}

	// comments
	function fetchComments(){
		TodolistsService.getComments().$promise.then(function(todolistComments){
			$scope.todolistComments = todolistComments;
		});
	}
	$scope.newComment = {};
	var uploader = $scope.uploader = new FileUploader({});
	$scope.submitComment = function(newComment) {
		if (newComment.notifyMembersType === 'all'){
			newComment.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (newComment.notifyMembersType === 'some' ) {
			newComment.notifyMembers = $scope.checkedEmailMembers;
		} else if (newComment.notifyMembersType === 'none'){
			newComment.notifyMembers = [];
		}
		if ($scope.uploader.queue.length > 0) {
			var promises = [];
			_.each($scope.uploader.queue,function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				fd.append('visibility', $scope.todolist.visibility);
				promises.push(TodolistsService.createAttachment(fd).$promise)
			});
			$q.all(promises).then(function(responses){
				newComment.attachments = responses;
				TodolistsService.createComment(newComment).$promise.then(function(res) {
					fetchComments();
					$scope.newComment = {};
					$scope.uploader.queue = [];
				});
			})
		} else {
			TodolistsService.createComment(newComment).$promise.then(function(res) {
				fetchComments();
				$scope.uploader.queue = [];
				$scope.newComment = {};
			});
		}
	}
	$scope.deleteComment = function(comment) {
		TodolistsService.removeComment(comment).$promise.then(function(res){
			$scope.attachments = [];
			fetchComments();
		})
	}
	$scope.saveCommentChange = function(comment){
		TodolistsService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.deleteAttachment = function(comment, attachment){
		var index = comment.attachments.indexOf(attachment)
		if (index > -1){
			comment.attachments.splice(index, 1);
		};
		var newAttachmentList = comment.attachments;
		TodolistsService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
});
